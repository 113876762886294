import React from 'react';
import { useSelector } from 'react-redux';
import { Wrapper } from '../../components/Ui';
import ProgressBar from '../../components/ProgressBar';
import Notification from '../../components/HomeBlocks/Notification';

const MyPrizes = () => {
  const buoniSopraContent = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Sezione buoni/scontrini sopra la lista',
  )?.contents;
  const buoniSottoContent = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Sezione buoni/scontrini sotto la lista',
  )?.contents;
  const progressBarCms = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Progress Bar',
  )?.contents;

  return (
    <Wrapper>
      {buoniSopraContent?.map((c, k) => {
        return <Notification key={c.id} notif={c} bg={c.img} width={c.width} />;
      })}
      <ProgressBar contentSteps={progressBarCms} step={2} />
      {buoniSottoContent?.map((c, i, arr) => {
        return <Notification key={c.id} data={arr} notif={c} bg={c.img} width={c.width} />;
      })}
    </Wrapper>
  );
};

export default MyPrizes;
