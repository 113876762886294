import React, { useEffect, useRef, useState } from 'react';
import Wrapper from '../../components/Ui/Wrapper';
import styled from 'styled-components';
import { Flex, Button, Loader, Text } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import customAxiosNm from '../../config/axios-refresh-token';
import ProgressBar from '../../components/ProgressBar';
import Notification from '../../components/HomeBlocks/Notification';

const Container = styled.div`
  max-width: ${props => props.theme.containerWidth}px;
  margin: auto;
  padding: 20px;
  ${respondTo.sm`
    padding: 20px;
  `};
`;
function MovieVouchers() {
  const [codeList, setCodeList] = useState([]);
  const [voucherList, setVoucherList] = useState([]);
  const [loading, setLoading] = useState(true);
  const generalContents = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Contenuti generali',
  );

  const immagineBuoni = generalContents?.contents?.find(c => c.name === 'immagineBuoni');

  const buoniSopraContent = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Sezione buoni/scontrini sopra la lista',
  )?.contents;
  const buoniSottoContent = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Sezione buoni/scontrini sotto la lista',
  )?.contents;
  const progressBarCms = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Progress Bar',
  )?.contents;

  const scrollRef = useRef();
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resCodesList = await customAxiosNm.get(`qmi/voucher/getcodes`, {
          params: {
            pah_promo: 0,
          },
        });
        setLoading(false);
        const resVoucherList = await customAxiosNm.get(`qmi/voucher/getvouchers`);
        setCodeList(resCodesList.data?.Results?.codes);
        setVoucherList(resVoucherList.data?.Results?.vouchers);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Wrapper>
      {buoniSopraContent?.map((c, k) => {
        return <Notification key={c.id} notif={c} bg={c.img} width={c.width} />;
      })}
      <ProgressBar contentSteps={progressBarCms} step={2} />
      {buoniSottoContent?.map((c, i, arr) => {
        return <Notification key={c.id} data={arr} notif={c} bg={c.img} width={c.width} />;
      })}
      <>
        {loading ? (
          <Loader show={loading} />
        ) : codeList?.length > 0 ? (
          <Container>
            <Table>
              <Thead>
                <TableRow>
                  <TableHeader>Codice</TableHeader>
                  <TableHeader>Numero di ingressi</TableHeader>
                  <TableHeader>Utilizzabile entro il</TableHeader>
                  <TableHeader>GENERA VOUCHER</TableHeader>
                </TableRow>
              </Thead>
              <tbody>
                {codeList.map(
                  ({
                    code_id,
                    code,
                    code_usable_times,
                    code_used_times,
                    code_valid_from,
                    code_valid_until,
                    voucher_printable_from,
                    voucher_printable_to,
                    code_type,
                    pah_promo,
                  }) => (
                    <TableRow key={code}>
                      <CodeCell>
                        <Lable>Codice:</Lable>
                        <div>
                          {code}
                          <SubText>{code_type}</SubText>
                        </div>
                      </CodeCell>
                      <TableCell>
                        <Flex gap={5} align='center' justify='center'>
                          <Lable>Numero di ingressi:</Lable>
                          {code_usable_times}
                        </Flex>
                      </TableCell>
                      <TableCell>
                        <Flex gap={5} align='center' justify='center'>
                          <Lable>Utilizzabile entro il:</Lable>
                          {new Intl.DateTimeFormat('it').format(new Date(code_valid_until))}
                        </Flex>
                      </TableCell>
                      <TableCell>
                        {+code_used_times < +code_usable_times ? (
                          <Link
                            to={{
                              pathname: '/voucher/' + code,
                              state: { voucher_printable_to, voucher_printable_from, pah_promo },
                            }}
                          >
                            <Button
                              height='38px'
                              fontSize={14}
                              style={{
                                display: 'inline-block',
                                background: 'green',
                                borderColor: 'green',
                              }}
                              disabled={
                                new Date() < new Date(code_valid_from) ||
                                new Date() > new Date(code_valid_until)
                              }
                            >
                              {new Date() < new Date(code_valid_from)
                                ? 'Stampabile dal ' +
                                  new Intl.DateTimeFormat('it').format(new Date(code_valid_from))
                                : new Date() > new Date(code_valid_until)
                                ? 'Scaduto il ' +
                                  new Intl.DateTimeFormat('it').format(new Date(code_valid_until))
                                : 'UTILIZZA'}
                            </Button>
                          </Link>
                        ) : (
                          +code_used_times === +code_usable_times && (
                            <Link
                              to={{
                                pathname: '/voucher/' + code,
                                state: { voucher_printable_to, voucher_printable_from, pah_promo },
                              }}
                            >
                              <Button
                                height='38px'
                                fontSize={14}
                                disabled
                                style={{
                                  display: 'inline-block',
                                  background: 'red',
                                  borderColor: 'red',
                                }}
                              >
                                USATO
                              </Button>
                            </Link>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </tbody>
            </Table>

            <Grid gap={15} justify='space-between'>
              {voucherList?.map(voucher =>
                voucher?.voucherList?.map(item => (
                  <SingleCard data={item} img={immagineBuoni?.img} />
                )),
              )}
            </Grid>
          </Container>
        ) : (
          <Text bold align='center' margin='20px 0'>
            Nessun buono disponibile
          </Text>
        )}
      </>
    </Wrapper>
  );
}
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  padding: 0 10px;
  ${respondTo.sm`
      grid-template-columns: 1fr 1fr;
       padding: 0 ;
    `}
`;

const SingleCard = ({ data, img }) => {
  const { code, cinema, link, pah_ticket_valid_on } = data || {};
  return (
    <Card>
      <CardTitle>{cinema}</CardTitle>
      <Flex style={{ marginBottom: '30px' }}>
        <ImageContainer>
          <Image src={img} alt='' />
        </ImageContainer>
        <CardContent>
          <CodeContainer>
            <CodeText>{code}</CodeText>
          </CodeContainer>
          <DateText>
            Utilizzabile solo il
            <br />
            {pah_ticket_valid_on}
          </DateText>
        </CardContent>
      </Flex>

      {false ? (
        <ExpiredButton>SCADUTO</ExpiredButton>
      ) : (
        <a
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          style={{ display: 'inline-block' }}
        >
          <Button active height='38px' fontSize={14}>
            SCARICA VOUCHER
          </Button>
        </a>
      )}
    </Card>
  );
};
const Card = styled.div`
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  padding: 20px;
  text-align: center;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
const Lable = styled.span`
  font-weight: bold;
  margin-left: 5px;
  font-size: 16px;
  color: #000;
  display: block;
  ${respondTo.sm`
    display:none;
  `}
`;
const CardTitle = styled.h2`
  font-size: 16px !important;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-right: 1px solid lightgrey;
`;

const CardContent = styled.div`
  width: 50%;
  padding: 0 30px;
`;
const Image = styled.img``;

const CodeContainer = styled.div`
  background-color: #fff;
  border-radius: ${({ theme }) => theme.border_radius_generale_piccolo};
  padding: 10px;
  margin-bottom: 10px;
`;

const CodeText = styled.p`
  font-size: 14px;
  color: #333;
  margin: 0;
`;

const DateText = styled.p`
  font-size: 14px;
  color: #333;
  margin: 0;
  font-weight: bold;
`;

const ExpiredButton = styled.button`
  background-color: #666;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: not-allowed;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
`;

const Table = styled.table`
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  .btn-scarica {
    text-transform: none;
    width: 100%;
    justify-content: center;
    position: relative;
    top: -1px;
    margin-bottom: -3px;
    @media (min-width: 769px) {
      margin-left: 15px;
      width: calc(100% - 15px);
    }
  }
`;
const Thead = styled.thead`
  display: none;
  @media (min-width: 769px) {
    display: table-header-group;
  }
`;

const TableHeader = styled.th`
  display: none;
  background-color: #fff;
  color: #333;
  font-weight: 400;
  padding: 12px;
  border-bottom: 2px solid ${({ theme }) => (theme.bordoTabella ? theme.bordoTabella : '#ddd')};
  &.no-border {
    border: 0;
  }
  @media (min-width: 769px) {
    display: table-cell;
  }
`;

const TableRow = styled.tr`
  display: flex;
  flex-direction: column;

  padding: 10px;
  margin: 0 10px 10px 10px;
  background-color: #cecdcd;

  &:nth-child(even) {
    background-color: #e4e4e4;
  }

  @media (min-width: 769px) {
    display: table-row;
    background-color: transparent;
    margin: 0 0 10px 0;
    &:nth-child(even) {
      background-color: transparent;
    }
  }
`;

const TableCell = styled.td`
  display: flex;
  justify-content: center;
  padding: 12px;
  font-weight: 600;
  color: ${({ theme }) =>
    theme.colore_contenuto_tabella ? theme.colore_contenuto_tabella : theme.default_text_color};
  @media (min-width: 769px) {
    display: table-cell;
    border-bottom: 2px solid ${({ theme }) => (theme.bordoTabella ? theme.bordoTabella : '#ddd')};
    &.no-border {
      border-bottom: 0;
    }
  }

  a {
    display: inline-block;
  }
`;

const CodeCell = styled(TableCell)`
  font-weight: normal;
  display: flex;

  gap: 5px;
  @media (min-width: 769px) {
    text-align: left;
    font-weight: bold;
  }
`;

const SubText = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 4px;
`;

export default MovieVouchers;
