import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import customAxiosNm from '../../config/axios-refresh-token';
import { Wrapper, Flex, Text, Button, Loader } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import Plus from '../../components/Ui/Icons/Plus';
import downloadIcon from '../../assets/images/download.svg';
import * as allStyles from '../../theme/theme';
import ProgressBar from '../../components/ProgressBar';
import Notification from '../../components/HomeBlocks/Notification';

const Container = styled.div`
  max-width: ${props => props.theme.containerWidth}px;
  margin: auto;
  padding: 20px;
  ${respondTo.sm`
    padding: 20px;
  `};
`;

const MyReceipts = props => {
  const slug = 'get';
  const games = useSelector(state => state.game.games);

  const buoniSopraContent = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Sezione buoni/scontrini sopra la lista',
  )?.contents;
  const buoniSottoContent = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Sezione buoni/scontrini sotto la lista',
  )?.contents;
  const progressBarCms = useSelector(state => state.cms.base)?.find(
    item => item.name === 'Progress Bar',
  )?.contents;

  const [receiptList, setReceiptList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resReceiptList = await customAxiosNm.get(`game/${slug}/${games[0]?.id}/getreceipts`);
        setReceiptList(resReceiptList?.data?.receipts);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Wrapper>
      {buoniSopraContent?.map((c, k) => {
        return <Notification key={c.id} notif={c} bg={c.img} width={c.width} />;
      })}
      <ProgressBar contentSteps={progressBarCms} step={2} />
      {buoniSottoContent?.map((c, i, arr) => {
        return <Notification key={c.id} data={arr} notif={c} bg={c.img} width={c.width} />;
      })}
      {loading ? (
        <Loader show={loading} />
      ) : receiptList?.length > 0 ? (
        <Container>
          <Text color='#333333' bold primary size={21}>
            Scontrini caricati
          </Text>
          <Table>
            <Thead>
              <TableRow>
                <TableHeader>Data e Ora</TableHeader>
                <TableHeader>Numero Scontrino</TableHeader>
                <TableHeader>Punto Vendita</TableHeader>
                <TableHeader>Importo Totale</TableHeader>
                <TableHeader className='no-border'></TableHeader>
              </TableRow>
            </Thead>
            <tbody>
              {receiptList.map(({ date, time, store, number, amount, image }) => (
                <TableRow key={number}>
                  <TableCell>
                    <Flex gap={5} align='center' justify='center'>
                      {date} - {time}
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <Flex gap={5} align='center' justify='center'>
                      {number}
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <Flex gap={5} align='center' justify='center'>
                      {store}
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <Flex gap={5} align='center' justify='center'>
                      {amount}
                    </Flex>
                  </TableCell>
                  <TableCell className='no-border' style={{ padding: 0 }}>
                    <Button tertiary color='white' className='btn-scarica'>
                      <a
                        target='_blank'
                        style={{ display: 'flex' }}
                        href={image}
                        rel='noopener noreferrer'
                      >
                        <Flex gap='10px'>
                          <img src={downloadIcon} alt='' />
                          <Text color='white' size='14'>
                            Scarica
                          </Text>
                        </Flex>
                      </a>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>

          {games[0] && (
            <Text
              as='div'
              margin='0 0 100px 0'
              onClick={() => (window.location.href = '/promo/' + slug + '/game/' + games[0]?.id)}
            >
              <Flex gap='5px' style={{ marginTop: '60px', cursor: 'pointer' }} justify='center'>
                <Plus color={allStyles.theme?.colore_link} />
                <Text size={18} bold decoration className='color-link'>
                  Carica un nuovo scontrino
                </Text>
              </Flex>
            </Text>
          )}
        </Container>
      ) : (
        <Text bold align='center' margin='20px 0'>
          Nessuno scontrino caricato
        </Text>
      )}
    </Wrapper>
  );
};

const Table = styled.table`
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  .btn-scarica {
    text-transform: none;
    width: 100%;
    justify-content: center;
    position: relative;
    top: -1px;
    margin-bottom: -3px;
    @media (min-width: 769px) {
      margin-left: 15px;
      width: calc(100% - 15px);
    }
  }
`;
const Thead = styled.thead`
  display: none;
  @media (min-width: 769px) {
    display: table-header-group;
  }
`;

const TableHeader = styled.th`
  display: none;
  background-color: #fff;
  color: #333;
  font-weight: 400;
  padding: 12px;
  border-bottom: 2px solid ${({ theme }) => (theme.bordoTabella ? theme.bordoTabella : '#ddd')};
  &.no-border {
    border: 0;
  }
  @media (min-width: 769px) {
    display: table-cell;
  }
`;

const TableRow = styled.tr`
  display: flex;
  flex-direction: column;

  padding: 10px;
  margin: 0 10px 10px 10px;
  background-color: #cecdcd;

  &:nth-child(even) {
    background-color: #e4e4e4;
  }

  @media (min-width: 769px) {
    display: table-row;
    background-color: transparent;
    margin: 0 0 10px 0;
    &:nth-child(even) {
      background-color: transparent;
    }
  }
`;

const TableCell = styled.td`
  display: flex;
  justify-content: center;
  padding: 12px;
  font-weight: 600;
  color: ${({ theme }) =>
    theme.colore_contenuto_tabella ? theme.colore_contenuto_tabella : theme.default_text_color};

  @media (min-width: 769px) {
    display: table-cell;
    border-bottom: 2px solid ${({ theme }) => (theme.bordoTabella ? theme.bordoTabella : '#ddd')};
    &.no-border {
      border-bottom: 0;
    }
  }

  a {
    display: inline-block;
  }
`;

export default MyReceipts;
